<template>
  <v-expansion-panels
    data-testid="hub-profile-downloads-container"
    class="shadow-regular"
    :value="open"
    flat
  >
    <v-expansion-panel class="rounded-lg pa-2">
      <v-expansion-panel-header class="heading-h3">
        <div class="d-flex align-center">
          <span data-testid="hub-profile-downloads-title" class="mr-2">
            {{ t('Title_.subTitle.downloads') }}
          </span>
        </div>
        <template #actions>
          <v-icon
            data-testid="hub-profile-downloads-chevron-icon"
            color="#212121"
          >
            mdi-chevron-down
          </v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-container class="px-0 pb-0">
          <v-row no-gutters class="uppercase-header">
            <v-col data-testid="hub-profile-downloads-file-header" cols="3">
              {{ t('Msg_.file') }}
            </v-col>
            <v-col
              data-testid="hub-profile-downloads-description-header"
              cols="7"
            >
              {{ t('Msg_.description') }}
            </v-col>
            <v-col
              data-testid="hub-profile-downloads-action-header"
              class="d-flex justify-center"
              cols="2"
              >{{ t('Msg_.action') }}
            </v-col>
          </v-row>

          <v-row
            no-gutters
            v-for="(download, index) in hubDownloads"
            :key="index"
            :data-testid="`hub-profile-downloads-content-container-${index}`"
          >
            <v-container pa-0 class="mb-3">
              <v-divider class="mt-3"></v-divider>
              <v-row class="mb-4 mt-4" no-gutters>
                <v-col
                  :data-testid="`hub-profile-downloads-file-name`"
                  class="section-detail mineBlack--text font-weight-medium"
                  cols="3"
                >
                  {{ download.fileName }}
                </v-col>
                <v-col
                  cols="7"
                  class="section-detail mineBlack--text"
                  :data-testid="`hub-profile-downloads-file-description`"
                >
                  {{ download.description }}
                </v-col>
                <v-col class="d-flex justify-center align-start" cols="2">
                  <IconButton
                    :data-testid="`hub-profile-downloads-download-btn`"
                    class="icon-btn"
                    icon="mdi-download"
                    color="blueRegular"
                    @click="downloadFile(download.fileUrl, download.fileName)"
                  />
                </v-col>
              </v-row>
              <v-row
                no-gutters
                v-if="download.filePassword"
                :data-testid="`hub-profile-downloads-password-section`"
              >
                <v-col cols="3" />
                <v-col class="d-flex" cols="7">
                  <span class="align-self-center pt-2">
                    {{ password(download.filePassword, download.showPassword) }}
                  </span>
                  <IconButton
                    :data-testid="`hub-profile-downloads-copy-password-btn`"
                    class="icon-btn ml-4"
                    icon="mdi-file-multiple-outline"
                    color="blueRegular"
                    icon-size="18"
                    :border="false"
                    @click="copyPasswordToClipboard(download.filePassword)"
                  />

                  <IconButton
                    :data-testid="`hub-profile-downloads-show-password-btn`"
                    class="icon-btn ml-2"
                    :icon="download.showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    color="blueRegular"
                    icon-size="18"
                    :border="false"
                    @click="toggleViewPassword(download)"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-row>
        </v-container>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script setup>
import languages from '../../locales/languages';
import IconButton from '@/components/shared/customComponents/buttons/icon-button.vue';
import { useRoute } from 'vue-router/composables';
import { useHubsStore } from '@/store/pinia/hubs.store.js';
import { useI18n } from 'vue-i18n-bridge';
import { ref } from 'vue';

const route = useRoute();
const hubsStore = useHubsStore();
const { t } = useI18n({ locale: 'en', messages: languages });

// --- Local variables ---
const open = ref(0);
const hubDownloads = ref([
  {
    fileName: '',
    description: '',
    fileUrl: '',
    filePassword: '',
    showPassword: false,
  },
]);

//--- Methods ---
const getHubDownloadsById = async () => {
  try {
    hubDownloads.value = await hubsStore.getHubDownloadsById(
      route.params.hubId
    );
  } catch (error) {
    console.log(error);
  }
};

const copyPasswordToClipboard = (password) => {
  if (password) {
    navigator.clipboard.writeText(password);
  }
};

const toggleViewPassword = (download) => {
  download.showPassword = !download.showPassword;
};

const password = (password, showPassword) => {
  if (password && showPassword) {
    return password;
  }
  return '**********';
};

const downloadFile = (url, fileName) => {
  window.open(
    `${process.env.VUE_APP_LOGIN_API}/v2/api/employee/downloadFile?url=${url}&FileName=${fileName}&download=true`
  );
};

// --- Lifecycle hooks ---
getHubDownloadsById();
</script>

<style scoped lang="scss">
.uppercase-header {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 11px;
  letter-spacing: 1.8px;
  color: $grey-regular;
}
</style>
